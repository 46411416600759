import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Range from './Layout/Range';
import Main from './Layout/Main';
import { useSelector } from 'react-redux';

function App() {
  const load = useSelector((state) => state.load.value);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); // 1.5 saniye

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className={`frontLoad ${!isLoading ? 'hidden' : ''}`}>
        <img className='loadIcon' src="/icons/esonucdarkblue.svg" alt="" />
      </div>
      <Header />
      <Range />
      <Main />
      <Footer />
    </div>
  );
}

export default App;