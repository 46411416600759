import React from 'react'

const Button = ({buttonText, colcol}) => {
    return (
        <div className='row w-full p-0 m-0'>
            <button className={`my-but-but col-md-${colcol} col-6 bg-[#77bfb460] mx-auto py-3 rounded-xl text-[#313f3a] font-bold text-lg`}>{buttonText}</button>
        </div>
    )
}

export default Button