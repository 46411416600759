import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loaddegis } from '../Stores/load';

const Header = () => {
    const dispatch = useDispatch();
    const load = useSelector((state) => state.load.value);

    const [heightState, setHeightState] = useState(10);
    const [roll, setRoll] = useState(false);
    const menuRef = useRef(null);

    const headHeight = () => {
        setHeightState(prevState => prevState === 10 ? 70 : 10);
        setRoll(!roll);
    };

    const handleClick = () => {
        const row1Element = document.querySelector('.row-1');
        const row2Element = document.querySelector('.row-2');
        const rowhidElement = document.querySelector('.row-hid');
        const row3Element = document.querySelector('.row-3');

        dispatch(loaddegis());

        if (row1Element && row2Element && rowhidElement && row3Element) {
            row1Element.classList.toggle('row-1-c');
            row2Element.classList.toggle('row-2-c');
            rowhidElement.classList.toggle('row-hid-c');
            row3Element.classList.toggle('row-3-c');
        }
    };

    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setHeightState(10);  // Menü kapanması için heightState'i 10 yap
            handleClick();  // Menü sınıflarını geri değiştir
        }
    };

    useEffect(() => {
        if (heightState === 70) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [heightState]);

    return (
        <header ref={menuRef} className={`fixed z-50 w-100 h-[${heightState}vh] duration-700 text-white bg-gradient-to-br from-[-150%] via-[50%] to-[250%] from-[#00295c] via-[#77bfb4] to-[#00295c] overflow-hidden ${roll?"rounded-b-[3rem]":"rounded-b-[0rem]"} `}>
            <div className="container con-h">
                <div className="row h-100 d-flex align-items-center">
                    <div className="col-md-3 col-6 d-flex justify-content-md-start justify-content-center align-items-center">
                        <NavLink to='/'>
                            <img className="logo1" src='../logo/2.png' alt="Logo" />
                        </NavLink>
                    </div>
                    <div className='col-md-4 d-md-block d-none'></div>
                    <div className="col-md-5 h-100 d-md-block d-none">
                        <nav className="d-flex justify-content-around align-items-center h-100">
                            <NavLink className="header-a" to="/">ANASAYFA</NavLink>
                            <NavLink className="header-a" to="/Kategoriler">KATEGORİLER</NavLink>
                            <NavLink className="header-a" to="/Hakkimizda">HAKKIMIZDA</NavLink>
                            <NavLink className="header-a" to="/Iletisim">İLETİŞİM</NavLink>
                        </nav>
                    </div>
                    <div className="col-6 h-100 d-md-none d-flex justify-center items-center">
                        <div className="ham" onClick={() => {
                            handleClick();
                            headHeight();
                        }}>
                            <span className={`row-1 duration-500`}></span>
                            <span className={`row-2 duration-500`}></span>
                            <span className={`row-hid duration-500`}></span>
                            <span className={`row-3 duration-500`}></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`container h-[70vh] p-0 m-0 overflow-hidden flex justify-center items-center`}>
                <ul className='row p-0 m-0 h-[60vh] w-100 flex object-cover flex-col justify-center items-center translate-y-[-5vh]'>
                    <li className='p-0 m-0 py-4 text-center'>
                        <NavLink to="/" className='p-0 m-0 text-white' onClick={() => {
                            handleClick();
                            headHeight();
                        }}>ANASAYFA</NavLink>
                    </li>
                    <li className='p-0 m-0 py-4 text-center'>
                        <NavLink to="/Kategoriler" className='p-0 m-0 text-white' onClick={() => {
                            handleClick();
                            headHeight();
                        }}>KATEGORİLER</NavLink>
                    </li>
                    <li className='p-0 m-0 py-4 text-center'>
                        <NavLink to="/Hakkimizda" className='p-0 m-0 text-white' onClick={() => {
                            handleClick();
                            headHeight();
                        }}>HAKKIMIZDA</NavLink>
                    </li>
                    <li className='p-0 m-0 py-4 text-center'>
                        <NavLink to="/Iletisim" className='p-0 m-0 text-white' onClick={() => {
                            handleClick();
                            headHeight();
                        }}>İLETİŞİM</NavLink>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Header;