import React from 'react'
import { NavLink } from 'react-router-dom'
const Kategori = ({ companies, categories }) => {


  return (
    <div className='container'>
      <div className='row'>
        <h1 className='text-center main-header'>Kategoriler</h1>
      </div>
      <br />
      <br />
      <div className="row ">
        <div id="0" className="query"></div>
        {
          categories.map((x, index) => {
            return <nav className="col-lg-4 col-sm-6 col-12 categories query" key={index}>
              <NavLink to={`/Kategoriler/${x.categoryName}/${x.categoryId}`} className="categories-alt border-[#00295c] border-[1px]" >
                <img className='alt-img' src={`data:image/png;base64, ${x.categoryImg}`} alt="" />

              </NavLink>

              <div className='d-flex justify-center items-center pt-2 mx-5 bg-[#77bfb460] rounded-b-full text-center'>
                <h4 className='text-[20px] fnt-fix'>{x.categoryName}</h4>
              </div>
            </nav>

          })
        }

      </div>
    </div>
  )
}

export default Kategori