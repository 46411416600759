import React from 'react'

const Hakkimizda = () => {
  return (
    <div className='container'>
      <div className='row'>
        <h1 className='text-center main-header'>Hakkımızda</h1>
      </div>
      <br />
      <br />
      <div className='row'>
        <p className='alt-text text-justify'>
          Modern dünyada sağlık hizmetleri ve eğitim gibi birçok alanda dijitalleşme hız kazanmıştır. Kullanıcılar, kan tahlili, sınav sonuçları ve benzeri bilgilerine çevrimiçi olarak ulaşmak istediklerinde, bazen karmaşık web siteleri ve uzun süreçlerle karşılaşabilirler. Bu noktada, esonuc platformu devreye girer. Kullanıcıların çeşitli sonuçlara hızlı ve kolay bir şekilde ulaşmalarını sağlamak amacıyla kurulmuş olan esonuc, bu süreci basitleştirerek zaman kaybını önler. Misyonumuz, kullanıcıların ihtiyaç duydukları sonuçlara en hızlı ve en pratik şekilde ulaşmalarını sağlamak. esonuc olarak, kullanıcı deneyimini her zaman ön planda tutuyor ve aradığınız sonuç sayfasına sadece birkaç tıklama ile ulaşmanızı hedefliyoruz. Kullanıcı dostu arayüzümüz ve kapsamlı arama motorumuz ile hizmet sağlayıcılarının karmaşık web siteleri arasında kaybolmadan, ihtiyaç duyduğunuz bilgiyi en kısa sürede elde etmenizi sağlıyoruz.

          esonuc, kullanıcılarına basit ve etkili bir çözüm sunar. Platformumuzda yer alan kapsamlı arama motoru sayesinde, çeşitli sağlık ve eğitim hizmet sağlayıcılarını kolayca aratabilirsiniz. İlgili hizmet sağlayıcının sonuç görme sayfasına doğrudan yönlendirildiğinizde, karmaşık menülerle uğraşmadan sonuçlarınıza ulaşabilirsiniz. Bu sistem, kullanıcıların zamanlarını verimli bir şekilde kullanmalarını sağlar ve sonuçlara erişimi kolaylaştırır. Kullanıcı Dostu: Kullanıcılarımızın ihtiyaçlarını anlamak ve en iyi deneyimi sunmak için sürekli çalışıyoruz. Kullanıcı geri bildirimlerine önem vererek, platformumuzu sürekli olarak geliştiriyoruz. Hız ve Kolaylık: Sonuçlara ulaşmanın hızlı ve kolay olmasını sağlamak için tasarlandık. Kullanıcılarımızın değerli zamanlarını boşa harcamadan, ihtiyaç duydukları bilgilere anında ulaşmalarını hedefliyoruz. Güvenilirlik: Bilgilerinize güvenli bir şekilde ulaşmanızı ve bu bilgilerin doğru olmasını sağlıyoruz. Güvenlik önlemlerimiz ve doğrulama süreçlerimiz ile kullanıcılarımızın bilgilerini koruma altına alıyoruz.

          Sorularınız veya geri bildirimleriniz için bizimle iletişime geçmekten çekinmeyin. Sizlere en iyi hizmeti sunmak ve deneyiminizi daha da iyileştirmek için buradayız. Her türlü öneri ve sorularınız için bize ulaşabilirsiniz. esonuc ekibi, sağlık ve eğitim sektörlerinde deneyimli profesyonellerden oluşmaktadır. Amacımız, dijitalleşen dünyada kullanıcıların hayatını kolaylaştırmak ve sonuçlara erişimi en pratik hale getirmektir. Teknoloji ve kullanıcı deneyimi konusundaki uzmanlığımız ile sürekli olarak daha iyi çözümler sunmak için çalışıyoruz. Gelecek hedeflerimiz arasında, daha fazla hizmet sağlayıcısını platformumuza ekleyerek, kullanıcılarımızın ihtiyaç duyduğu her türlü sonuca erişimini sağlamak bulunmaktadır. Ayrıca, mobil uygulama geliştirme planlarımız ile kullanıcılarımızın her an her yerden sonuçlarına ulaşmalarını kolaylaştırmayı hedefliyoruz.
        </p>
      </div>
      {/* <div className='row  mt-5 d-md-flex d-none '>
        <div className='ovhid-about col-12 mx-auto '>
          <img className='obcov-about ' src="/images/esonuc.svg" alt="health" />
        </div>
      </div> */}
    </div>
  )
}

export default Hakkimizda