import React from 'react'

const LongAd = () => {
  return (
    <div className='h-[90vh] d-md-flex d-none overflow-hidden z-40 col-md-2 col-0  bg-transparent d-flex justify-center items-center p-0 m-0 px-4 '>
      <div className='h-[80vh] w-[400px] overflow-hidden '>
        {/* <img className='object-cover h-full w-[100%]' src="../../images/test-ad.gif" alt="" /> */}
      </div>
    </div>
  )
}

export default LongAd