import React from 'react'

const Iletisim = () => {

  return (
    <div className='container'>
      <div className='row'>
        <h1 className='text-center main-header'>İletişim</h1>
      </div>
      <br />
      <br />
      <div className='row'>
        <div className='col-md-8 col-12 mx-auto'>
          <form className='bg-[#77bfb460] p-4 py-5 rounded border-[1px] border-[#00295c]'>
            <div className='mb-4 mx-md-5 mx-0'>
              <label htmlFor='name' className='block text-textgreen font-bold mb-2'>
                Ad
              </label>
              <input
                type='text'
                id='name'
                name='name'
                className='w-full p-2 border border-textgreen rounded'
                placeholder='Adınızı girin'
              />
            </div>
            <div className='mb-4 mx-md-5 mx-0'>
              <label htmlFor='email' className='block text-textgreen font-bold mb-2'>
                E-posta
              </label>
              <input
                type='email'
                id='email'
                name='email'
                className='w-full p-2 border border-textgreen rounded'
                placeholder='E-posta adresinizi girin'
              />
            </div>
            <div className='mb-4 mx-md-5 mx-0'>
              <label htmlFor='subject' className='block text-textgreen font-bold mb-2'>
                Konu
              </label>
              <input
                type='text'
                id='subject'
                name='subject'
                className='w-full p-2 border border-textgreen rounded'
                placeholder='Konuyu girin'
              />
            </div>
            <div className='mb-4 mx-md-5 mx-0'>
              <label htmlFor='message' className='block text-textgreen font-bold mb-2'>
                Mesaj
              </label>
              <textarea
                id='message'
                name='message'
                className='w-full p-2 border border-textgreen rounded'
                placeholder='Mesajınızı girin'
                rows='4'
              ></textarea>
            </div>
            <div className='text-center'>
              <button
                type='submit'
                className='bg-white text-[#00295c] font-bold py-2 px-4 rounded hover:bg-gray-200'
              >
                Gönder
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Iletisim