import React, { useEffect, useState } from 'react'
import Anasayfa from '../Pages/Anasayfa'
import Kategori from '../Pages/Kategori'
import Hakkimizda from '../Pages/Hakkimizda'
import Iletisim from '../Pages/Iletisim'
import Company from '../Pages/Company'
import { Routes, Route } from 'react-router'
import { loaddegis } from '../Stores/load';
import { loadfalse } from '../Stores/load';
import { loadtrue } from '../Stores/load';
import { useDispatch, useSelector } from 'react-redux';
import LongAd from '../Exter/LongAd';
import HorizAd from '../Exter/HorizAd';
import KategoriAlt from '../Pages/KategoriAlt'
const Main = () => {
    const load = useSelector((state) => state.load.value);
    const [companies, setCompanies] = useState([]);
    const [categories, setCategories] = useState([]);
    const getCompanies = async () => {
        try {
            const response = await fetch("https://api.esonuc.com/api/Company/GetCompanies");
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCompanies(data);
        } catch (error) {
            console.error('getCompanies veri çekilirken hata oluştu:', error);
        }
    };

    const getCategories = async () => {
        try {
            const response = await fetch("https://api.esonuc.com/api/Category/GetCategories");
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('getCategories veri çekilirken hata oluştu:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getCategories();
            await getCompanies();
        };

        if (load) {
            fetchData();
        }
    }, [load]);

    return (

        companies.length > 0 && categories.length > 0 && <section className={`container-fluid p-0 m-0 bg-[#00295c09] `}>
            <div className={`h-[100vh] w-[100vw]  fixed  ${load ? " bg-transparent glu duration-500" : " bg-black bg-opacity-50 flu duration-500"}  `} ></div>

            {/* <div className='row mx-auto p-0 m-0 overflow-hidden '>
                <HorizAd />
            </div> */}

            <div className={`row mx-auto p-0 m-0 `}>
                <LongAd />
                <main className="py-5 p-0 m-0 col-md-8 col-12 main-h bg-white container">
                    <div className='row p-0 m-0 mx-4'>
                        {companies.length > 0 && <Routes>
                            <Route path='/' element={<Anasayfa companies={companies} categories={categories} />} />
                            <Route path='/Kategoriler' element={<Kategori companies={companies} categories={categories} />} />
                            <Route path='/Hakkimizda' element={<Hakkimizda companies={companies} categories={categories} />} />
                            <Route path='/Iletisim' element={<Iletisim companies={companies} categories={categories} />} />
                            <Route path={`/:kategoriAdi/:sirketAd/:sirketId`} element={<Company companies={companies} categories={categories} />} />
                            <Route path={`/Kategoriler/:kategoriAdi/:kategoriId`} element={<KategoriAlt categories={categories} />} />
                            {/* {
                                companies.map((x, index) => {
                                    return <Route path={`/kategoriler/hastane/${x.companyUrl}-${x.companyId}`} key={index} element={<Hastane companies={companies} />} />
                                })
                            } */}

                        </Routes>}
                    </div>
                </main>
                <LongAd />
            </div>

        </section>
    )
}

export default Main