import React, { useEffect } from 'react'
import Fuse from 'fuse.js'
import { useState } from 'react'
import { Route, Routes, Link, NavLink, useLocation } from "react-router-dom";

import Button from '../Exter/Button'
const Anasayfa = ({ companies, categories }) => {

    const location = useLocation();
    const [resultData, setResultData] = useState([])

    const fuseOptions = {
        keys: ['companyName'],
    };
    const fuse = new Fuse(companies, fuseOptions);
    const searchChangeHandle = (e) => {
        const result = fuse.search(e.target.value)
        setResultData(result)

    }
    const clearSearch = () => {
        document.getElementById("searchInput").value = "";
        setResultData([])
    }


    return (
        <div className="container p-0 m-0">
            <div className='row p-0 m-0'>

                <div className='p-0 m-0 py-5 flex flex-col justify-center items-center col-md-10 mx-auto col-12 bg-gradient-to-br from-[-150%] via-[50%] to-[250%] from-[#00295c] via-[#77bfb4] to-[#00295c] mb-5 rounded-2xl'>

                    <div className='col-12 pb-5 d-flex justify-center items-center'>
                        <h1 className='text-textgreen text-center text-[46px]'>Aradığın Sonucu Yaz</h1>
                    </div>
                    <div className='container-fluid  p-0 m-0 mx-auto'>
                        <div className='row  p-0 m-0 mx-auto justify-center'>
                            <div className=' h-[12rem] mx-auto absolute col-md-7 col-12 md:rounded-3xl max-md:rounded-md bg-goldgreen shadow-2xl  p-0 border-white'></div>

                        </div>
                    </div>

                    <div className="col-12 py-16 relative  flex-col justify-center items-center ">
                        <div className='h-[4rem]  w-[100%]  '>
                            <input className=" searchInput w-[100%] h-[100%] " type="text" id="searchInput" placeholder="Hastane Ara" onChange={searchChangeHandle} />
                        </div>
                        <div className='result  mt-[2rem] flex flex-col justify-center items-center col-12'>

                            <div className='clearInput-top'>
                                <img
                                    onClick={clearSearch}
                                    className='clearInput p-0 m-0' src="/icons/xmark-solid.svg" id='clearInputBtn' alt="" />
                            </div>


                            {resultData.length > 0 && resultData.map((result, index) => (
                                <div className='container p-0 result-alt mt-[5px] border-stone-900 border-b-[1px] border-r-[1px] border-l-[1px] ' key={index}>
                                    <div className='row p-0 m-0 '>
                                        <Link to={`/${categories.find(x => x.categoryId === x.categoryId).categoryName}/${result.item.companyUrl}/${result.item.companyId}`} className='d-flex justify-content-center align-items-center  py-3  ' >
                                            <div className='col-1 h-[3rem] d-flex justify-center items-center m-0 p-0'>
                                                <img className='p-0 m-0 h-fit' src={`data:image/png;base64, ${result.item.companyImg}`} alt="" />
                                            </div>
                                            <div className='col-11 '>
                                                <h1 className=' prostegma p-0 m-0'>{result.item.companyName}</h1>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className='col-12 pt-5 d-flex justify-center items-center '>
                        <ul className='flex justify-center items-center p-0 m-0 '>
                            {
                                categories.slice(0,6).map((x, index) => {
                                    return <div className='flex flex-col p-0 m-0 justify-center items-center py-md-0 py-3 mx-2 rounded-md blurtr-top'>
                                        <NavLink to={`/Kategoriler/${x.categoryName}/${x.categoryId}`} className='  cursor-pointer h-[9rem] w-[9rem] bg-transparent blurtr flex flex-col justify-center items-center icon-ov'>
                                            <img className='icon-ob' src={`data:image/png;base64, ${x.categoryImg}`} alt="" />
                                        </NavLink>
                                        <NavLink to={`/Kategoriler/${x.categoryName}/${x.categoryId}`} className='w-full p-0 m-0 text-center'>

                                            <h4 className='font-regular'>{x.categoryName}</h4>
                                        </NavLink>

                                    </div>

                                })
                            }








                        </ul>

                    </div>

                </div>

            </div>

            <div className="row mt-5">
                <div id="0" className="query "></div>
                {
                    companies.slice(0,12).map((x, index) => {
                        return <nav className=" col-lg-4 col-sm-6 col-12 categories query" key={index}>
                            <NavLink to={`/${categories.find(x => x.categoryId === x.categoryId).categoryName}/${x.companyUrl}/${x.companyId}`} className="categories-alt border-[#00295c] border-[1px]">
                                <img className='alt-img' src={`data:image/png;base64, ${x.companyImg}`} alt="" />
                            </NavLink>
                            <div className='d-flex justify-center items-center pt-2 mx-5 bg-[#77bfb460] rounded-b-full text-center'>
                                <h4 className='text-[20px] fnt-fix'>{x.companyName}</h4>
                            </div>
                        </nav>
                        


                    })
                }
                {/* {
                    companies.map((x, index) => {
                        return <nav className=" col-lg-4 col-sm-6 col-12 categories query" key={index}>
                            <NavLink to={`/kategoriler/${categories.find(x => x.categoryId === x.categoryId).categoryName}/${x.companyUrl}-${x.companyId}`} className="categories-alt border-[#00295c] border-[1px]">
                                <img className='alt-img' src={`data:image/png;base64, ${x.companyImg}`} alt="" />
                            </NavLink>
                        </nav>


                    })
                } */}

            </div>
            <div className='mt-5'>
                <NavLink to={"/Kategoriler"}>
                    <Button colcol={3} buttonText={"Tümünü Gör"} />
                </NavLink>
            </div>
        </div>
    )
}

export default Anasayfa