import React from 'react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../Exter/Button';
import { useLocation, useParams } from 'react-router-dom';

const KategoriAlt = ({ categories }) => {
    let { kategoriAdi, kategoriId } = useParams();

    console.log("Kategori Adı:", kategoriAdi)
    console.log("Kategori Id:", kategoriId)
    console.log("cat:", categories)

    const [sirketler, setSirketler] = useState([])
    const getCompany = () => {
        fetch(`https://api.esonuc.com/api/Company/GetCompaniesByCategoryId?categoryId=${kategoriId}`)
            .then((response) => response.json())
            .then((result) => {
                setSirketler(result)
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        getCompany()
    }, [])

    return (
        sirketler.length > -1 && <div className="container p-0">
            <div className="row p-0 m-0">
                <h2 className='text-center main-header'>{kategoriAdi}</h2>


                <br />
                <br />
                <br />
                <br />
                <br />

                {
                    sirketler.map((x, i) => {
                        return <nav className=" col-lg-4 col-sm-6 col-12 categories query" key={i}>
                            <NavLink to={`/${kategoriAdi}/${x.companyUrl}/${x.companyId}`} className="categories-alt border-[#00295c] border-[1px]">
                                <img className='alt-img' src={`data:image/png;base64, ${x.companyImg}`} alt="" />
                            </NavLink>
                            <div className='d-flex justify-center items-center pt-2 mx-5 bg-[#77bfb460] rounded-b-full text-center'>
                                <h4 className='text-[20px] fnt-fix'>{x.companyName}</h4>
                            </div>

                        </nav>


                    })
                }
            </div>

        </div>
    )
}

export default KategoriAlt