import React from 'react'

const Footer = () => {
  return (
    <footer className="text-white py-4 bg-gradient-to-br from-[-150%] via-[50%] to-[250%] from-[#00295c] via-[#77bfb4] to-[#00295c]" >
    <div className="container ">
        <div className="row ">
            <div className="col-12 mx-auto ">
                <div className="d-flex flex-column justify-content-center align-items-center">
                 <img className=' flex items-center justify-center col-md-2 col-4' src="/logo/2.png" alt="" />
               <br />
                    <p className="p-0 m-0 text-white">Powered by &copy; rodesoft</p>
                </div>
            </div>

        </div>
    </div>
</footer>
  )
}

export default Footer