import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Button from '../Exter/Button'
import { useLocation, useParams } from 'react-router-dom';

const Hastane = ({ companies }) => {

    let { kategoriAdi, sirketAd, sirketId } = useParams();

    console.log("Kategori Adı:", kategoriAdi)
    console.log("Şirket Adı:", sirketAd)
    console.log("Şirket Id:", sirketId)

    const [sirket, setSirket] = useState({
        companyId: null,
        categoryId: null,
        companyName: "",
        companyImg: "",
        companyLink: "",
        companyUrl: ""
    })




    const getCompany = () => {
        fetch(`https://api.esonuc.com/api/Company/GetCompany?companyId=${sirketId}`)
            .then((response) => response.json())
            .then((result) => {
                setSirket({ ...result })
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
            window.scrollTo(0, 0);
          
        getCompany()
    }, [])


    return (
        sirket.companyId && <div className="container p-0">
            <div className="row p-0 m-0 shadow-2xl py-4 rounded-lg">
                {/* RESPONSIVE */}
                <div className="d-md-none d-flex col-md-9 col-12 d-flex justify-content-center align-items-center" >
                    <h1 className="head-1 text-center text-[60px] max-lg:text-[52px] max-md:text-[42px] max-sm:text-[32px] max-md:pb-12">
                        {sirket.companyName}
                    </h1>
                </div>
                {/* RESPONSIVE */}
                {/* {
            )map((x, index) => {
                        return <div className="col-md-3 col-12" key={index}>
                            <div id="" className="ov-hid-img mx-auto query h-[16rem] w-[16rem]  max-lg:h-[14rem] max-lg:w-[14rem] max-md:h-[12rem] max-md:w-[12rem] max-sm:h-[10rem] max-sm:w-[10rem]">
                                <img className='ob-cov-img alt-img' src={`data:image/png;base64, ${x.companyImg}`} alt="" />
                            </div>
                        </div>
                    })
                } */}
                <div className="flex justify-center items-center col-md-3 col-12" >
                    <div id="" className="ov-hid-img shadow-2xl mx-auto query h-[16rem] w-[16rem]  max-lg:h-[12rem] max-lg:w-[12rem] max-md:h-[12rem] max-md:w-[12rem] max-sm:h-[10rem] max-sm:w-[10rem]">
                        <img className='ob-cov-img alt-img' src={`data:image/png;base64, ${sirket.companyImg}`} alt="" />
                    </div>
                </div>

                <div className="d-md-flex flex-col p-0  d-none col-md-6 col-12 d-flex justify-content-start align-items-center" >
                    <h1 className="w-full flex justify-start items-center h-[40%] m-0 head-1 text-center text-[60px] max-lg:text-[52px] max-md:text-[42px] max-sm:text-[32px] max-md:pt-12">
                        {sirket.companyName}
                    </h1>
                    <ul className='flex flex-col justify-start items-start  m-0 w-full'>
                        <li className='p-0 m-0'>Kategori: {kategoriAdi}</li>
                        <li className='p-0 m-0'>Kurum: {sirket.companyName}</li>


                    </ul>
                </div>
                <a className="d-md-flex d-none col-md-3 col-12 flex justify-center items-center" href={sirket.companyLink} target='_blank'>
                    <Button colcol={12} buttonText={"Sonuca Git"} />
                </a>

            </div>
            <br />
            <br />
            <br />
            {/* <div className="row p-0 m-0">
                <div className="col-12 p-0 m-0">
                    <div className="col-12 d-flex justify-content-center align-items-center p-0 m-0">
                        <h1 className="p-0 m-0 text-center text-[40px] max-lg:text-[36px] max-md:text-[32px] max-sm:text-[28px]">{sirket.companyName} BİLGİLERİ</h1>
                    </div>
                    <br />
                    <div className="col-12 p-0 m-0">
                        <p className="p-0 m-0 text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima eius
                            repellendus magni
                            expedita voluptate quae eos dolore culpa, delectus debitis sed commodi officia cum
                            assumenda
                            aspernatur voluptatum quos possimus eum.
                            Veritatis doloribus impedit praesentium aut non adipisci esse hic magnam totam expedita
                            quos
                            minima, aperiam at consequatur dolore odit magni consectetur nesciunt asperiores
                            accusantium
                            consequuntur distinctio. Eaque placeat perferendis debitis?
                            Molestias, vitae? Magni nulla optio eaque reprehenderit accusamus, culpa suscipit a
                            laborum
                            harum rem accusantium ipsa fuga. Repudiandae, vero quam! Quia obcaecati sunt accusamus
                            similique velit ad sit voluptate quae.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima eius
                            repellendus magni
                            expedita voluptate quae eos dolore culpa, delectus debitis sed commodi officia cum
                            assumenda
                            aspernatur voluptatum quos possimus eum.
                            Veritatis doloribus impedit praesentium aut non adipisci esse hic magnam totam expedita
                            quos
                            minima, aperiam at consequatur dolore odit magni consectetur nesciunt asperiores
                            accusantium
                            consequuntur distinctio. Eaque placeat perferendis debitis?
                            Molestias, vitae? Magni nulla optio eaque reprehenderit accusamus, culpa suscipit a
                            laborum
                            harum rem accusantium ipsa fuga. Repudiandae, vero quam! Quia obcaecati sunt accusamus
                            similique velit ad sit voluptate quae.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima eius
                            repellendus magni
                            expedita voluptate quae eos dolore culpa, delectus debitis sed commodi officia cum
                            assumenda
                            aspernatur voluptatum quos possimus eum.
                            Veritatis doloribus impedit praesentium aut non adipisci esse hic magnam totam expedita
                            quos
                            minima, aperiam at consequatur dolore odit magni consectetur nesciunt asperiores
                            accusantium
                            consequuntur distinctio. Eaque placeat perferendis debitis?
                            Molestias, vitae? Magni nulla optio eaque reprehenderit accusamus, culpa suscipit a
                            laborum
                            harum rem accusantium ipsa fuga. Repudiandae, vero quam! Quia obcaecati sunt accusamus
                            similique velit ad sit voluptate quae.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima eius
                            repellendus magni
                            expedita voluptate quae eos dolore culpa, delectus debitis sed commodi officia cum
                            assumenda
                            aspernatur voluptatum quos possimus eum.
                            Veritatis doloribus impedit praesentium aut non adipisci esse hic magnam totam expedita
                            quos
                            minima, aperiam at consequatur dolore odit magni consectetur nesciunt asperiores
                            accusantium
                            consequuntur distinctio. Eaque placeat perferendis debitis?
                            Molestias, vitae? Magni nulla optio eaque reprehenderit accusamus, culpa suscipit a
                            laborum
                            harum rem accusantium ipsa fuga. Repudiandae, vero quam! Quia obcaecati sunt accusamus
                            similique velit ad sit voluptate quae.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima eius
                            repellendus magni
                            expedita voluptate quae eos dolore culpa, delectus debitis sed commodi officia cum
                            assumenda
                            aspernatur voluptatum quos possimus eum.
                            Veritatis doloribus impedit praesentium aut non adipisci esse hic magnam totam expedita
                            quos
                            minima, aperiam at consequatur dolore odit magni consectetur nesciunt asperiores
                            accusantium
                            consequuntur distinctio. Eaque placeat perferendis debitis?
                            Molestias, vitae? Magni nulla optio eaque reprehenderit accusamus, culpa suscipit a
                            laborum
                            harum rem accusantium ipsa fuga. Repudiandae, vero quam! Quia obcaecati sunt accusamus
                            similique velit ad sit voluptate quae.
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <a href={sirket.companyLink} target='_blank'>
                    <Button colcol={3} buttonText={"Sonuca Git"} />
                </a>
            </div> */}
        </div>
    )

}

export default Hastane